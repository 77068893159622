import axios from 'axios';

const URL = process.env.REACT_APP_LOCAL === 'true' ?
  'http://localhost:8000/api/reset-password/' :
  'https://byhmgardplatform-webshop.azurewebsites.net/api/reset-password/';
const CONFIRM_URL = process.env.REACT_APP_LOCAL === 'true' ?
  'http://localhost:8000/api/reset-password/complete/' :
  'https://byhmgardplatform-webshop.azurewebsites.net/api/reset-password/complete/';

const requestPasswordReset = async (email: string) => {
  const res = await axios.post(URL, {
    'email': email,
  });
  return res.data;
};

const confirmPasswordReset = async (token: string) => {
  const res = await axios.get(CONFIRM_URL + token + '/');
  return res.data;
};

const setNewPassword = async (token: string, password: string) => {
  const res = await axios.post(CONFIRM_URL + token + '/', {
    'password': password,
  });
  return res.data;
};

const actions = {
  requestPasswordReset,
  confirmPasswordReset,
  setNewPassword,
};

export default actions;
