import InstallationInputType from '../types/InstallationInputType';

export const montageInputs: InstallationInputType[] = [
  {
    suffix: 'tim',
    type: 'number',
    label: 'Löpande arbete montage',
    name: 'hours_solar_montage',
  },
  {
    suffix: 'tim',
    type: 'number',
    label: 'Löpande arbete elektriker',
    name: 'hours_solar_electrician',
  },
  {
    suffix: 'tim',
    type: 'number',
    label: 'ÄTA Löpande arbete montage',
    name: 'ata_hours_solar_montage',
  },
  {
    suffix: 'tim',
    type: 'number',
    label: 'ÄTA Löpande arbete elektriker',
    name: 'ata_hours_solar_electrician',
  },
  {
    suffix: 'st',
    type: 'number',
    label: 'Antal förbesiktningar',
    name: 'inspection_qty',
  },
  {
    suffix: 'st',
    type: 'number',
    label: 'Antal plattor (papptak)',
    name: 'plate_qty',
  },
  {
    suffix: 'mil',
    type: 'number',
    label: 'Avstånd till anläggningen',
    name: 'distance_facility',
  },
  {
    suffix: 'st',
    type: 'number',
    label: 'Arbetsdagar',
    name: 'work_days',
  },
  {
    suffix: 'st',
    type: 'number',
    label: 'Antal arbetare',
    name: 'workers',
  },
  {
    suffix: 'tim',
    type: 'float',
    label: 'Restsuffix',
    name: 'travel_time',
  },
  {
    suffix: 'm',
    type: 'number',
    label: 'Extra meter AC kabel',
    name: 'cable_length',
  },
  {
    suffix: 'kr',
    type: 'number',
    label: 'Fraktkostnad',
    name: 'shipping',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Infält elmätarskåp',
    name: 'electrical_cabinet',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Uppsäkring',
    name: 'fuse',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Smart meter',
    name: 'smart_meter',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Checkwatt',
    name: 'checkwatt',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Cross rail',
    name: 'crossrail',
  },
  {
    suffix: '',
    type: 'checkbox',
    label: 'Övrigt förbrukningsmaterial',
    name: 'other_material',
  },
];
