import axios, {RawAxiosRequestHeaders} from 'axios';
import {authService} from './authService';

const URL = process.env.REACT_APP_LOCAL === 'true' ?
'http://localhost:8000/api' :
'https://byhmgardplatform-webshop.azurewebsites.net/api/';

const client = axios.create({
  baseURL: URL,
});

client.interceptors.request.use(function(config) {
  config!.headers!.Authorization = `Bearer ${authService.tokenValue}`;
  return config;
});

client.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    authService.refreshAccessToken().then((res) => {
      error.config.headers = JSON.parse(JSON.stringify(error.config.headers || {})) as RawAxiosRequestHeaders;
      error.config.headers['Authorization'] = 'Bearer ' + authService.tokenValue;
      return axios.request(error.config);
    }).catch((err) => {
      if (err.response && [400, 401, 403].includes(err.response.status)) {
        authService.logout();
      }
    });
  }
  return Promise.reject(error);
});

export default client;
