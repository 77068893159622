import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {AxiosError} from 'axios';
import getQuoteProductsFiltered from '../../../utils/getQuoteProductsFiltered';

import useCalculateQuoteGreenTax from '../../../hooks/quotes/useCalculateQuoteGreenTax';
import useCheckTaskStatus from '../../../hooks/quotes/useCheckTaskStatus';
import useClearQuoteGreenTax from '../../../hooks/quotes/useClearQuoteGreenTax';
import useUpdateQuote from '../../../hooks/quotes/useUpdateQuote';
import useGeneratePDF from '../../../hooks/quotes/useGeneratePDF';
import useMutateCreateProject from '../../../hooks/projectManagement/useMutateCreateProject';

import {selectedProductsFilteredByType} from '../../../state/products/products';
import {
  calculationQuoteState,
  currentCalculationTab,
  currentTask,
} from '../../../state/calculation/calculation';

import QuoteEmailModal from '../../Modals/QuoteEmailModal';
import QuotePDFModal from '../../Modals/QuotePDFModal';
import QuoteSaveTemplateModal from '../../Modals/QuoteSaveTemplateModal';
import UICard from '../../UICommon/UICard';
import UICheckbox from '../../UICommon/UICheckbox';
import UILargeButton from '../../UICommon/UILargeButton';
import CalculationOfferCosts from './CalculationOfferCosts';
import CalculationOfferDeductions from './CalculationOfferDeductions';
import CalculationOfferMaterials from './CalculationOfferMaterials';
import CalculationOfferTotalSummary from './CalculationOfferTotalSummary';
import CalculationOfferCustomCost from './CalculationOfferCustomCost';
import UIErrorIndicatorSmall from '../../UICommon/UIErrorIndicatorSmall';
import UIUnderlineButton from '../../UICommon/UIUnderlineButton';
import ConfirmModal from '../../Modals/ConfirmModal';

const CalculationOfferTab: FC = () => {
  const {t} = useTranslation();
  const setCurrentTab = useSetRecoilState(currentCalculationTab);
  const quote = useRecoilValue(calculationQuoteState);
  const hasGreenTax = quote.deductions && quote.deductions.length > 0;
  const [greenTaxChecked, setGreenTaxChecked] = useState(hasGreenTax);
  const [isPdfModal, setIsPdfModal] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [task, setTask] = useRecoilState(currentTask);
  const [newGen, setNewGen] = useState(false);
  const [sendAsEmail, setSendAsEmail] = useState(false);
  const [isProposal, setProposal] = useState(true);
  const isPrivatePerson = quote.customer.type === 'private person';
  const [error, setError] = useState('');
  const setFilteredProducts = useSetRecoilState(selectedProductsFilteredByType);

  const otherCosts = quote.costs.filter((cost: any) => cost.category === 'other');
  const materialCosts = quote.costs.filter((cost: any) => cost.category === 'material');
  const workCosts = quote.costs.filter((cost: any) => ['electrician', 'mounting'].includes(cost.category));

  const {
    mutate: mutateCalculateGreenTax,
    error: greenTaxError,
  } = useCalculateQuoteGreenTax();
  const {mutate: mutateClearQuoteGreenTax} = useClearQuoteGreenTax();
  const {mutate: mutateStatus} = useCheckTaskStatus();
  const {mutate: mutateGeneratePDF} = useGeneratePDF();
  const {mutate: mutateUpdateQuote} = useUpdateQuote({
    name: quote.name,
    is_template: true,
  });
  const {
    mutate: mutateCreateProject,
  } = useMutateCreateProject();

  useEffect(() => {
    setFilteredProducts(getQuoteProductsFiltered(quote));
  }, []);

  const goToPreviousPage = () => {
    setCurrentTab('statistics');
  };

  useEffect(() => {
    const greenErrorMessage = greenTaxError instanceof AxiosError && greenTaxError?.response?.data;
    if (greenErrorMessage) {
      setError(greenErrorMessage);
    }
  }, [greenTaxError]);

  useEffect(() => {
    if (newGen) {
      mutateGeneratePDF(quote.id!);
      setNewGen(false);
    } else if (task.isLoading) {
      const intervalId = setInterval(() => {
        mutateStatus();
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [task]);


  const generatePdf = () => {
    const taskData = (
      {...task, id: null,
        isLoading: true,
        isSuccess: false,
        data: {...task.data, isProposal: isProposal, sendAsEmail: sendAsEmail,
        },
      }
    );
    setNewGen(true);
    setTask(taskData);
    setIsPdfModal(true);
  };

  const toggleGreenTax = () => {
    if (!greenTaxChecked) {
      mutateCalculateGreenTax();
      setGreenTaxChecked(true);
    } else {
      mutateClearQuoteGreenTax();
      setGreenTaxChecked(false);
    }
  };

  const saveAsTemplate = () => {
    mutateUpdateQuote();
    setIsTemplateModal(true);
  };

  const onCreateProjectManagement = () => {
    setIsProjectModal(false);
    const data = {'quote': quote.id};
    mutateCreateProject(data);
  };

  return (
    <div className="w-full h-[75%] overflow-y-scroll">
      <div
        className='flex space-x-6'
      >
        <h2 className="font-bold text-lg mb-4">
          {isProposal ?
            t('calculation.pages.quote.proposal') :
            t('calculation.pages.quote.quote')}
        </h2>
        {error &&
          <UIErrorIndicatorSmall
            message={error}
          />}
      </div>
      {isPrivatePerson &&
        <UICheckbox
          label={t('calculation.financial.greenDeduction')}
          name="green_tax"
          checked={hasGreenTax}
          onChange={toggleGreenTax}
          disabled={!quote.is_installation_updated}
          tooltip={!quote.is_installation_updated ? t('calculation.pages.quote.greenDeductionNotAvailable')! : ''}
        />
      }
      <UICheckbox
        label={t('calculation.pages.quote.proposal')}
        name="proposal"
        onChange={() => setProposal(!isProposal)}
        checked={isProposal}
      />
      <div
        className='space-y-4 mt-6'
      >
        {(quote.installation_cost || 0) > 0 &&
        <CalculationOfferCosts
          costs={workCosts}
          price={quote.installation_cost || 0}
          tax={quote.installation_tax || 0}
          isPrivatePerson={isPrivatePerson}
        />}
        {(quote.material_price || 0) > 0 &&
        <CalculationOfferMaterials
          price={quote.material_price || 0}
          tax={quote.material_tax || 0}
          isPrivatePerson={isPrivatePerson}
          materialCosts={materialCosts}
        />}
        {otherCosts.length > 0 &&
        <CalculationOfferCustomCost
          otherCosts={otherCosts}
          isPrivatePerson={isPrivatePerson}
        />}
        {hasGreenTax && (
          <CalculationOfferDeductions
            deductions={quote.deductions}
            possibleDeduction={quote.deduction_cost}
          />
        )}
        <CalculationOfferTotalSummary
          sum={(quote.material_price || 0) + (quote.installation_cost || 0) + (quote.custom_cost || 0)}
          deductions={quote.deduction_cost || 0}
          totalCost={quote.total_cost || 0}
          taxes={quote.total_tax || 0}
          isPrivatePerson={isPrivatePerson}
        />
      </div>
      <h2 className="font-bold text-lg my-6">
        {t('calculation.pages.quote.deliveryMethods.title')}
      </h2>
      <UICard style="mb-6">
        <button
          className="text-left"
          onClick={() => setSendAsEmail(false)}
        >
          <div className="w-full flex p-4 border-b border-[#CCCCCC]">
            <input
              type="radio"
              readOnly={true}
              checked={!sendAsEmail}
            />
            <div className="w-full pl-4">
              <div className="font-semibold">
                {t('calculation.pages.quote.deliveryMethods.pdf.title')}
              </div>
              <div className="text-sm text-[#1E1E1E]">
                {t('calculation.pages.quote.deliveryMethods.pdf.description')}
              </div>
            </div>
          </div>
        </button>
        <button
          className={'text-left'}
          onClick={() => setSendAsEmail(true)}
          disabled={quote.is_template}
        >
          <div className="w-full flex p-4">
            <input
              type="radio"
              readOnly={true}
              disabled={quote.is_template}
              checked={sendAsEmail}
            />
            <div
              className={`w-full pl-4 ${quote.is_template ? 'opacity-30' : ''}`}
              title={quote.is_template ?
                 t('calculation.pages.quote.notAvailableForTemplates')! : ''}
            >
              <div className="font-semibold">
                {t('calculation.pages.quote.deliveryMethods.email.title')}
              </div>
              <div className="text-sm text-[#1E1E1E]">
                {t('calculation.pages.quote.deliveryMethods.email.description')}
              </div>
            </div>
          </div>
        </button>
      </UICard>
      <div className="w-full flex flex-col items-end">
        <div className="w-full flex justify-between">
          <div>
            <UILargeButton
              label={t('calculation.pages.quote.buttons.createProject')}
              width="w-auto"
              onClick={() => setIsProjectModal(true)}
              disabled={quote.is_template}
              toolTip={quote.is_template ? t('calculation.pages.quote.notAvailableForTemplates')! : ''}
            />
          </div>
          <div
            className='space-x-4'
          >
            <UILargeButton
              label={t('calculation.pages.quote.buttons.createTemplate')}
              width="w-auto"
              onClick={saveAsTemplate}
              disabled={quote.is_template}
              toolTip={quote.is_template ? t('calculation.pages.quote.notAvailableForTemplates')! : ''}
            />
            <UILargeButton
              label={t('calculation.pages.quote.buttons.submit')}
              width="w-auto"
              onClick={generatePdf}
              disabled={task.isLoading}
            />
          </div>
        </div>
        <div className="text-center">
          <UIUnderlineButton
            label={t('calculation.pages.quote.buttons.goBack')}
            onClick={goToPreviousPage}
          />
        </div>
      </div>
      {isPdfModal && (
        <QuotePDFModal
          task={task}
          closeModal={() => setIsPdfModal(false)}
          isProposal={isProposal}
          sendAsEmail={sendAsEmail}
        />
      )}
      {isEmailModal && (
        <QuoteEmailModal closeModal={setIsEmailModal} isProposal={isProposal} />
      )}
      {isTemplateModal && (
        <QuoteSaveTemplateModal closeModal={setIsTemplateModal} />
      )}
      {isProjectModal &&
        <ConfirmModal
          title={t('calculation.pages.quote.projectManagementModal.confirmTitle')}
          onCancel={() => setIsProjectModal(false)}
          onConfirm={onCreateProjectManagement}
        />
      }
    </div>
  );
};

export default CalculationOfferTab;
