import {format} from 'date-fns';
import {sv} from 'date-fns/locale';
import {toCurrency} from '../utils/toCurrency';
import {customerStatusSelect} from './customerForm';
import CalculationTabType from '../types/CalculationTabType';
import {FiLayout, FiSliders, FiUsers} from 'react-icons/fi';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {FaList} from 'react-icons/fa';
import React from 'react';
import AppLayoutMenuPage from '../types/AppLayoutMenuType';

export const swedishStatus = (status: string) => {
  return customerStatusSelect.map((el: any) => {
    if (el.value === status) {
      return el.label;
    }
  });
};

export const homeQuotesTemplatesColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'Skapad den',
    Cell: ({value}: any) => format(new Date(value.replace(/\s+/g, 'T')), 'yyyy/MM/dd', {locale: sv}),
  },
];


export const appPages: AppLayoutMenuPage[] = [
  {
    url: '/hem',
    name: 'overview',
    icon: <FiLayout className="text-2xl"/>,
  },
  {
    url: '/kunder',
    name: 'customers',
    icon: <FiUsers className="text-2xl"/>,
  },
  {
    url: '/kalkyl/mallar',
    name: 'templates',
    icon: <HiOutlineDocumentText className="text-2xl"/>,
  },
  {
    url: '/kalkyl',
    name: 'calculation',
    icon: <FiSliders className="text-2xl"/>,
  },
  {
    url: '/projektledning',
    name: 'projectManagement',
    icon: <FaList className="text-xl"/>,
  },
];

export const calculationTabs: CalculationTabType[] = [
  {
    id: 1,
    name: 'Kontakt',
    accessor: 'contact',
  },
  {
    id: 2,
    name: 'Sektioner',
    accessor: 'sections',
  },
  {
    id: 3,
    name: 'Produkter',
    accessor: 'products',
  },
  {
    id: 4,
    name: 'Material',
    accessor: 'material',
    isRequired: true,
  },
  {
    id: 5,
    name: 'Arbete',
    accessor: 'montage',
  },
  {
    id: 6,
    name: 'Statistik',
    accessor: 'statistics',
  },
  {
    id: 7,
    name: 'Ekonomi & Leverans',
    accessor: 'quote',
  },
];

export const calculationContactTabs = [
  {
    id: 1,
    name: 'Kontakt',
    accessor: 'contact',
  },
  {
    id: 2,
    name: 'Adress',
    accessor: 'location',
  },
  {
    id: 3,
    name: 'Fastighet',
    accessor: 'property',
  },
  {
    id: 4,
    name: 'Översikt',
    accessor: 'customerOverview',
  },
  {
    id: 5,
    name: 'Kalkyl',
    accessor: 'quote',
  },
  {
    id: 6,
    name: 'Dokument',
    accessor: 'documents',
  },
];

export const productTypes = [
  'inverter',
  'battery',
  'chargingbox',
  'genericproduct',
];

export const solarPanelsColumns = [
  {
    id: 'id',
    accessor: 'id',
    Header: 'ID',
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
  },
  {
    id: 'price.cost',
    accessor: 'price.cost',
    Header: 'Inköpspris, ex moms',
    Cell: ({value}: any) => toCurrency(value),
  },
  {
    id: 'width_mm',
    accessor: 'width_mm',
    Header: 'Bredd (mm)',
  },
  {
    id: 'height_mm',
    accessor: 'height_mm',
    Header: 'Höjd (mm)',
  },
  {
    id: 'effect_w',
    accessor: 'effect_w',
    Header: 'Watts',
  },
  {
    id: 'brand',
    accessor: 'brand',
    Header: 'Märke',
  },
];

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const invertersColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
    Cell: ({row}: any) => (row.original.name),
  },
  {
    id: 'brand.name',
    accessor: 'brand.name',
    Header: 'Märke',
  },
  {
    id: 'price.cost',
    accessor: 'price.cost',
    Header: 'Inköpspris, ex moms',
    Cell: ({value}: any) => toCurrency(value),
  },
  {
    id: 'has_battery',
    accessor: 'has_battery',
    Header: 'Har batteri',
    Cell: ({value}: any) => value ? 'Ja' : 'Nej',
  },
  {
    id: 'is_ferroamp',
    accessor: 'is_ferroamp',
    Header: 'Ferroamp',
    Cell: ({value}: any) => value ? 'Ja' : 'Nej',
  },
  {
    id: 'kWH',
    accessor: 'kWH',
    Header: 'Watts',
    Cell: ({value}: any) => value + ' kW',
  },
  {
    id: 'MPPT',
    accessor: 'MPPT',
    Header: 'MPPT',
    Cell: ({value}: any) => value + '-fas',
  },
];

export const batteriesColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
  },
  {
    id: 'brand.name',
    accessor: 'brand.name',
    Header: 'Märke',
  },
  {
    id: 'price.cost',
    accessor: 'price.cost',
    Header: 'Inköpspris, ex moms',
    Cell: ({value}: any) => toCurrency(value),
  },
  {
    id: 'kWH',
    accessor: 'kWH',
    Header: 'Watts',
    Cell: ({value}: any) => value + ' kWh',
  },
];

export const chargingBoxesColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
  },
  {
    id: 'brand.name',
    accessor: 'brand.name',
    Header: 'Märke',
  },
  {
    id: 'price.cost',
    accessor: 'price.cost',
    Header: 'Inköpspris, ex moms',
    Cell: ({value}: any) => toCurrency(value),
  },
];

export const genericProductsColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'Namn',
  },
  {
    id: 'type',
    accessor: 'type',
    Header: 'Typ',
  },
  {
    id: 'price.cost',
    accessor: 'price.cost',
    Header: 'Inköpspris, ex moms',
    Cell: ({value}: any) => toCurrency(value),
  },
];

export const AG_GRID_LOCALE_SV = {
  selectAll: '(Välj alla)',
  selectAllSearchResults: '(Välj alla sökresultat)',
  addCurrentSelectionToFilter: 'Lägg till aktuell markering i filtret',
  searchOoo: 'Sök...',
  blanks: '(Tomma)',
  noMatches: 'Inga träffar',
  filterOoo: 'Filtrera...',
  equals: 'Lika med',
  notEqual: 'Inte lika med',
  blank: 'Tom',
  notBlank: 'Inte tom',
  empty: 'Välj en',
  lessThan: 'Mindre än',
  greaterThan: 'Större än',
  lessThanOrEqual: 'Mindre än eller lika med',
  greaterThanOrEqual: 'Större än eller lika med',
  inRange: 'Inom intervallet',
  inRangeStart: 'från',
  inRangeEnd: 'till',
  contains: 'Innehåller',
  notContains: 'Innehåller inte',
  startsWith: 'Börjar med',
  endsWith: 'Slutar med',
  dateFormatOoo: 'åååå-mm-dd',
  andCondition: 'Och',
  orCondition: 'Eller',
  applyFilter: 'Tillämpa',
  resetFilter: 'Återställ',
  clearFilter: 'Rensa',
  cancelFilter: 'Avbryt',
  textFilter: 'Textfilter',
  numberFilter: 'Nummerfilter',
  dateFilter: 'Datumfilter',
  setFilter: 'Ange filter',
  groupFilterSelect: 'Välj fält:',
  advancedFilterContains: 'innehåller',
  advancedFilterNotContains: 'innehåller inte',
  advancedFilterTextEquals: 'är lika med',
  advancedFilterTextNotEqual: 'är inte lika med',
  advancedFilterStartsWith: 'börjar med',
  advancedFilterEndsWith: 'slutar med',
  advancedFilterBlank: 'är tomt',
  advancedFilterNotBlank: 'är inte tomt',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: 'är sant',
  advancedFilterFalse: 'är falskt',
  advancedFilterAnd: 'OCH',
  advancedFilterOr: 'ELLER',
  advancedFilterApply: 'Tillämpa',
  advancedFilterBuilder: 'Byggare',
  advancedFilterValidationMissingColumn: 'Saknar kolumn',
  advancedFilterValidationMissingOption: 'Saknar alternativ',
  advancedFilterValidationMissingValue: 'Saknar värde',
  advancedFilterValidationInvalidColumn: 'Kolumnen hittades inte',
  advancedFilterValidationInvalidOption: 'Alternativet hittades inte',
  advancedFilterValidationMissingQuote: 'Värdet saknar ett avslutande citat',
  advancedFilterValidationNotANumber: 'Värdet är inte ett nummer',
  advancedFilterValidationInvalidDate: 'Värdet är inte ett giltigt datum',
  advancedFilterValidationMissingCondition: 'Villkoret saknas',
  advancedFilterValidationJoinOperatorMismatch: 'Sammanlänkande operatorer inom ett villkor måste vara desamma',
  advancedFilterValidationInvalidJoinOperator: 'Sammanlänkande operator hittades inte',
  advancedFilterValidationMissingEndBracket: 'Saknar slutparentes',
  advancedFilterValidationExtraEndBracket: 'För många slutparenteser',
  advancedFilterValidationMessage: 'Uttrycket har ett fel. ${variabel1} - ${variabel2}.',
  advancedFilterValidationMessageAtEnd: 'Uttrycket har ett fel. ${variabel} i slutet av uttrycket.',
  advancedFilterBuilderTitle: 'Avancerat filter',
  advancedFilterBuilderApply: 'Tillämpa',
  advancedFilterBuilderCancel: 'Avbryt',
  advancedFilterBuilderAddButtonTooltip: 'Lägg till filter eller grupp',
  advancedFilterBuilderRemoveButtonTooltip: 'Ta bort',
  advancedFilterBuilderMoveUpButtonTooltip: 'Flytta upp',
  advancedFilterBuilderMoveDownButtonTooltip: 'Flytta ner',
  advancedFilterBuilderAddJoin: 'Lägg till grupp',
  advancedFilterBuilderAddCondition: 'Lägg till filter',
  advancedFilterBuilderSelectColumn: 'Välj en kolumn',
  advancedFilterBuilderSelectOption: 'Välj ett alternativ',
  advancedFilterBuilderEnterValue: 'Ange ett värde...',
  advancedFilterBuilderValidationAlreadyApplied: 'Aktuellt filter har redan tillämpats.',
  advancedFilterBuilderValidationIncomplete: 'Inte alla villkor är kompletta.',
  advancedFilterBuilderValidationSelectColumn: 'Måste välja en kolumn.',
  advancedFilterBuilderValidationSelectOption: 'Måste välja ett alternativ.',
  advancedFilterBuilderValidationEnterValue: 'Måste ange ett värde.',
  columns: 'Kolumner',
  filters: 'Filter',
  pivotMode: 'Pivotläge',
  groups: 'Radgrupper',
  rowGroupColumnsEmptyMessage: 'Dra hit för att ställa in radgrupper',
  values: 'Värden',
  valueColumnsEmptyMessage: 'Dra hit för att aggregera',
  pivots: 'Kolumnetiketter',
  pivotColumnsEmptyMessage: 'Dra hit för att ställa in kolumnetiketter',
  group: 'Grupp',
  rowDragRow: 'rad',
  rowDragRows: 'rader',
  loadingOoo: 'Laddar...',
  loadingError: 'FEL',
  noRowsToShow: 'Inga rader att visa',
  enabled: 'Aktiverad',
  pinColumn: 'Fäst kolumn',
  pinLeft: 'Fäst till vänster',
  pinRight: 'Fäst till höger',
  noPin: 'Ingen fästning',
  valueAggregation: 'Värdeaggregering',
  noAggregation: 'Ingen',
  autosizeThiscolumn: 'Autostorlek på denna kolumn',
  autosizeAllColumns: 'Autostorlek på alla kolumner',
  groupBy: 'Gruppera efter',
  ungroupBy: 'Avgruppera efter',
  ungroupAll: 'Avgruppera alla',
  addToValues: 'Lägg till ${variabel} till värden',
  removeFromValues: 'Ta bort ${variabel} från värden',
  addToLabels: 'Lägg till ${variabel} till etiketter',
  removeFromLabels: 'Ta bort ${variabel} från etiketter',
  resetColumns: 'Återställ kolumner',
  expandAll: 'Expandera alla radgrupper',
  collapseAll: 'Stäng alla radgrupper',
  copy: 'Kopiera',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: 'Kopiera med rubriker',
  copyWithGroupHeaders: 'Kopiera med grupperubriker',
  cut: 'Klipp ut',
  paste: 'Klistra in',
  ctrlV: 'Ctrl+V',
  export: 'Exportera',
  csvExport: 'CSV-export',
  excelExport: 'Excel-export',
  sum: 'Summa',
  first: 'Första',
  last: 'Sista',
  min: 'Min',
  max: 'Max',
  none: 'Ingen',
  count: 'Antal',
  avg: 'Genomsnitt',
  filteredRows: 'Filtrerade',
  selectedRows: 'Valda',
  totalRows: 'Totalt antal rader',
  totalAndFilteredRows: 'Rader',
  more: 'Mer',
  to: 'till',
  of: 'av',
  page: 'Sida',
  pageLastRowUnknown: '?',
  nextPage: 'Nästa sida',
  lastPage: 'Sista sidan',
  firstPage: 'Första sidan',
  previousPage: 'Föregående sida',
  pivotColumnGroupTotals: 'Total',
  pivotChartAndPivotMode: 'Pivotdiagram & Pivotläge',
  pivotChart: 'Pivotdiagram',
  chartRange: 'Diagramområde',
  columnChart: 'Kolumn',
  groupedColumn: 'Grupperad',
  stackedColumn: 'Staplad',
  normalizedColumn: '100% staplad',
  barChart: 'Stapeldiagram',
  groupedBar: 'Grupperad',
  stackedBar: 'Staplad',
  normalizedBar: '100% staplad',
  pieChart: 'Cirkeldiagram',
  pie: 'Cirkel',
  doughnut: 'Ringdiagram',
  line: 'Linje',
  xyChart: 'X Y (Spridningsdiagram)',
  scatter: 'Spridningsdiagram',
  bubble: 'Bubbel',
  areaChart: 'Områdediagram',
  area: 'Område',
  stackedArea: 'Staplad',
  normalizedArea: '100% staplad',
  histogramChart: 'Histogram',
  histogramFrequency: 'Frekvens',
  combinationChart: 'Kombinationsdiagram',
  columnLineCombo: 'Kolumn & Linje',
  AreaColumnCombo: 'Område & Kolumn',
  pivotChartTitle: 'Pivotdiagram',
  rangeChartTitle: 'Områdediagram',
  settings: 'Inställningar',
  data: 'Data',
  format: 'Format',
  categories: 'Kategorier',
  defaultCategory: '(Ingen)',
  series: 'Serier',
  xyValues: 'X Y Värden',
  paired: 'Parläge',
  axis: 'Axel',
  navigator: 'Navigator',
  color: 'Färg',
  thickness: 'Tjocklek',
  xType: 'X-typ',
  automatic: 'Automatisk',
  category: 'Kategori',
  number: 'Nummer',
  time: 'Tid',
  autoRotate: 'Auto rotera',
  xRotation: 'X-rotation',
  yRotation: 'Y-rotation',
  ticks: 'Tickmarkeringar',
  width: 'Bredd',
  height: 'Höjd',
  length: 'Längd',
  padding: 'Fyllning',
  spacing: 'Avstånd',
  chart: 'Diagram',
  title: 'Titel',
  titlePlaceholder: 'Diagramtitel - dubbelklicka för att redigera',
  background: 'Bakgrund',
  font: 'Typsnitt',
  top: 'Överst',
  right: 'Höger',
  bottom: 'Nederst',
  left: 'Vänster',
  labels: 'Etiketter',
  size: 'Storlek',
  minSize: 'Minsta storlek',
  maxSize: 'Största storlek',
  legend: 'Teckenförklaring',
  position: 'Position',
  markerSize: 'Markörens storlek',
  markerStroke: 'Markörens streck',
  markerPadding: 'Markörens fyllning',
  itemSpacing: 'Avstånd mellan objekten',
  itemPaddingX: 'Objektfyllning X',
  itemPaddingY: 'Objektfyllning Y',
  layoutHorizontalSpacing: 'Horisontellt avstånd',
  layoutVerticalSpacing: 'Vertikalt avstånd',
  strokeWidth: 'Streckbredd',
  lineDash: 'Linjedash',
  offset: 'Förskjutning',
  offsets: 'Förskjutningar',
  tooltips: 'Verktygstips',
  callout: 'Anropa',
  markers: 'Markörer',
  shadow: 'Skugga',
  blur: 'Osärskild',
  xOffset: 'X-förskjutning',
  yOffset: 'Y-förskjutning',
  lineWidth: 'Linjebredd',
  normal: 'Normal',
  bold: 'Fet',
  italic: 'Kursiv',
  boldItalic: 'Fet kursiv',
  predefined: 'Fördefinierad',
  fillOpacity: 'Fyllnadsopacitet',
  strokeOpacity: 'Linjeopacitet',
  histogramBinCount: 'Antal fack',
  columnGroup: 'Kolumn',
  barGroup: 'Stapel',
  pieGroup: 'Cirkel',
  lineGroup: 'Linje',
  scatterGroup: 'X Y (Spridningsdiagram)',
  areaGroup: 'Område',
  histogramGroup: 'Histogram',
  combinationGroup: 'Kombination',
  groupedColumnTooltip: 'Grupperad',
  stackedColumnTooltip: 'Staplad',
  normalizedColumnTooltip: '100% staplad',
  groupedBarTooltip: 'Grupperad',
  stackedBarTooltip: 'Staplad',
  normalizedBarTooltip: '100% staplad',
  pieTooltip: 'Cirkel',
  doughnutTooltip: 'Ring',
  lineTooltip: 'Linje',
  groupedAreaTooltip: 'Område',
  stackedAreaTooltip: 'Staplad',
  normalizedAreaTooltip: '100% staplad',
  scatterTooltip: 'Spridningsdiagram',
  bubbleTooltip: 'Bubbel',
  histogramTooltip: 'Histogram',
  columnLineComboTooltip: 'Kolumn & Linje',
  areaColumnComboTooltip: 'Område & Kolumn',
  customComboTooltip: 'Anpassad kombination',
  noDataToChart: 'Inga data tillgängliga för diagram.',
  pivotChartRequiresPivotMode: 'Pivotdiagram kräver aktiverat pivotläge.',
  chartSettingsToolbarTooltip: 'Meny',
  chartLinkToolbarTooltip: 'Länkad till rutnät',
  chartUnlinkToolbarTooltip: 'Avlänkad från rutnät',
  chartDownloadToolbarTooltip: 'Ladda ner diagram',
  seriesChartType: 'Seriediagramtyp',
  seriesType: 'Serietyp',
  secondaryAxis: 'Sekundär axel',
  ariaAdvancedFilterBuilderItem: '${variabel1}. Nivå ${variabel2}. Tryck på ENTER för att redigera.',
  // eslint-disable-next-line max-len
  ariaAdvancedFilterBuilderItemValidation: '${variabel1}. Nivå ${variabel2}. ${variabel3} Tryck på ENTER för att redigera.',
  ariaAdvancedFilterBuilderList: 'Lista över avancerade filterbyggare',
  ariaAdvancedFilterBuilderFilterItem: 'Filtervillkor',
  ariaAdvancedFilterBuilderGroupItem: 'Filtergrupp',
  ariaAdvancedFilterBuilderColumn: 'Kolumn',
  ariaAdvancedFilterBuilderOption: 'Alternativ',
  ariaAdvancedFilterBuilderValueP: 'Värde',
  ariaAdvancedFilterBuilderJoinOperator: 'Sammanlänkande operator',
  ariaAdvancedFilterInput: 'Inmatning av avancerat filter',
  ariaChecked: 'markerad',
  ariaColumn: 'Kolumn',
  ariaColumnGroup: 'Kolumngrupp',
  ariaColumnList: 'Kolumnlista',
  ariaColumnSelectAll: 'Växla markering av alla kolumner',
  ariaDateFilterInput: 'Inmatning av datumfilter',
  ariaDefaultListName: 'Lista',
  ariaFilterColumnsInput: 'Inmatning av filterkolumner',
  ariaFilterFromValue: 'Filtrera från värde',
  ariaFilterInput: 'Filterinmatning',
  ariaFilterList: 'Filterlista',
  ariaFilterToValue: 'Filtrera till värde',
  ariaFilterValue: 'Filtervärde',
  ariaFilterMenuOpen: 'Öppna filtermenyn',
  ariaFilteringOperator: 'Filteroperator',
  ariaHidden: 'dold',
  ariaIndeterminate: 'osäker',
  ariaInputEditor: 'Inmatningsredigerare',
  ariaMenuColumn: 'Tryck på CTRL ENTER för att öppna kolumnmenyn.',
  ariaRowDeselect: 'Tryck på MELLANSLAG för att avmarkera den här raden',
  ariaRowSelectAll: 'Tryck på MELLANSLAG för att växla markering av alla rader',
  ariaRowToggleSelection: 'Tryck på MELLANSLAG för att växla radmarkering',
  ariaRowSelect: 'Tryck på MELLANSLAG för att markera den här raden',
  ariaSearch: 'Sök',
  ariaSortableColumn: 'Tryck på ENTER för att sortera',
  ariaToggleVisibility: 'Tryck på MELLANSLAG för att växla synlighet',
  ariaToggleCellValue: 'Tryck på MELLANSLAG för att växla cellvärde',
  ariaUnchecked: 'avmarkerad',
  ariaVisible: 'synlig',
  ariaSearchFilterValues: 'Sök filtervärden',
  ariaRowGroupDropZonePanelLabel: 'Radgrupper',
  ariaValuesDropZonePanelLabel: 'Värden',
  ariaPivotDropZonePanelLabel: 'Kolumnetiketter',
  ariaDropZoneColumnComponentDescription: 'Tryck på DELETE för att ta bort',
  ariaDropZoneColumnValueItemDescription: 'Tryck på ENTER för att ändra aggregattypen',
  ariaDropZoneColumnGroupItemDescription: 'Tryck på ENTER för att sortera',
  ariaDropZoneColumnComponentAggFuncSeparator: ' av ',
  ariaDropZoneColumnComponentSortAscending: 'stigande',
  ariaDropZoneColumnComponentSortDescending: 'fallande',
  ariaLabelColumnMenu: 'Kolumnmeny',
  ariaLabelCellEditor: 'Cellredigerare',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Välj fält',
  ariaLabelRichSelectField: 'Rik valfält',
  ariaLabelTooltip: 'Verktygstips',
  ariaLabelContextMenu: 'Kontextmeny',
  ariaLabelSubMenu: 'Undermeny',
  ariaLabelAggregationFunction: 'Aggregeringsfunktion',
  ariaLabelAdvancedFilterAutocomplete: 'Autokomplettering av avancerat filter',
  ariaLabelAdvancedFilterBuilderAddField: 'Lägg till fält i avancerad filterbyggare',
  ariaLabelAdvancedFilterBuilderColumnSelectField: 'Välj fält i avancerad filterbyggare',
  ariaLabelAdvancedFilterBuilderOptionSelectField: 'Välj alternativ i avancerad filterbyggare',
  ariaLabelAdvancedFilterBuilderJoinSelectField: 'Välj sammanslutningsoperator i avancerad filterbyggare',
  thousandSeparator: ' ',
  decimalSeparator: ',',
  true: 'Sant',
  false: 'Falskt',
  invalidDate: 'Ogiltigt datum',
  invalidNumber: 'Ogiltigt nummer',
  january: 'Januari',
  february: 'Februari',
  march: 'Mars',
  april: 'April',
  may: 'Maj',
  june: 'Juni',
  july: 'Juli',
  august: 'Augusti',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'December',
};
